import { useMyCustomer } from '#imports';

export default defineNuxtRouteMiddleware(async () => {
    const { validateAccess } = useMyCustomer();

    const access = await validateAccess();

    // AH wants 401 in both cases, so we change them again here
    if (access?.success === false && access?.status === 403) {
        return abortNavigation({ statusCode: 401, statusMessage: 'notLoggedIn' });
    } else if (access?.success === false && access?.status === 401) {
        return abortNavigation({ statusCode: 401, statusMessage: 'notAllowed' });
    }
});
